<script setup>

defineProps({
    categoryOptions: Object,
    currentCategories: Array,
});

const emits = defineEmits(['update:currentCategories']);
</script>

<template>
    <div class="mt-4 flex justify-center gap-4 md:justify-start">
        <button
            v-for="(label, value) in categoryOptions"
            :key="value"
            :class="{
                'bg-orange text-white ': currentCategories.includes(value),
                'text-orange': !currentCategories.includes(value),
            }"
            class="min-h-10 w-fit self-start rounded border border-orange px-3 text-base  font-bold uppercase transition-colors"
            @click="emits('update:currentCategories', [value])"
        >
            {{ label }}
        </button>
    </div>
</template>
